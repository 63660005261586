html[data-v-61cc33d8],
body[data-v-61cc33d8] {
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
  background-color: #fff;
  color: black;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
a[data-v-61cc33d8] {
  margin: 0px;
  padding: 0px;
}
#profileBtn[data-v-61cc33d8]{
  height: 28px;
}
.grid-container[data-v-61cc33d8] {
  height: 100vh;
  display: grid;
  grid-template-rows: 50% 50%;
  /* grid-template-rows: 100%; */
}
.top-container-div[data-v-61cc33d8] {
  min-width: 0;
  text-align: center;
  width: auto;

  /* padding: 0px;  */
}

/* #vimeoPlayerContainer {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
} */
/* .react-player {
  height: 98%;
  padding-top: 56.25%;
  position: relative;
} */
.react-player > div[data-v-61cc33d8] {
  position: absolute;
}
.carousel-control-prev span[data-v-61cc33d8],
.carousel-control-next span[data-v-61cc33d8] {
  /* background-image: url("../img/btn_arrow_left.png") !important; */
  color: black;
}
.video-div[data-v-61cc33d8] {
  display: flex;
  align-items: center;
  width: 85vw;
}
.btn-disabled[data-v-61cc33d8] {
  opacity: .30;
}
.participant[data-v-61cc33d8] {
  align-items: center;
  margin: auto 0.5vh;
  height: 100%;
  /* width: 45vw; */
  /* padding: 1vh; */
  /* background-color: white; */
  /* margin: 1vh; */
}



/* These settings are controlled in bootstrap.scoped.css - not here!! */
.local-participant[data-v-61cc33d8] {
  width: 100%;
  display: flex;
  /* align-content: flex-end;
  justify-content: flex-end; */
  align-content: center;
  justify-content: center;
}
.remote-participants[data-v-61cc33d8] {
  width: 50%;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
}
.overlay[data-v-61cc33d8] {
  position: fixed;  /* Fixed to screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #272727; 
    /* Semi-transparent */
  z-index: 9;  /* Z-index set to 9 */
  opacity: 80%;
}
#camera-video-container[data-v-61cc33d8] {
  display: flex;
  flex-direction: column;
}