
.buttonarea[data-v-8490feb7] {
  /*display: inline-block;*/
  background-color: #cc3366;
  text-align: center;
  width: 100vw;
}
.buttonarea h2[data-v-8490feb7] {
  color: #cc3366;
  font-size: 26px;
  font-family: 'Nunito' !important;
  font-weight: 800;
}
.attendclass[data-v-8490feb7] {
  background-color: #fff;
  width: 260px;
  height: 260px;
  border-radius: 20px;
  display: inline-block;
  vertical-align: middle;
}
.gap[data-v-8490feb7] {
  display: inline-block;
  width: 10px;
}

/* OVERLAY */
#btn_happy[data-v-8490feb7]:hover,
#btn_sad[data-v-8490feb7]:hover,
#btn_worried[data-v-8490feb7]:hover {
  opacity: 1;
}
#btn_happy[data-v-8490feb7] {
  position: relative;
  top: -318px;
  left: -1px;
}
#btn_sad[data-v-8490feb7] {
  position: relative;
  top: -318px;
  right: -3px;
}
#btn_tired[data-v-8490feb7] {
  position: relative;
  left: -69px;
  top: -357px;
}
#btn_angry[data-v-8490feb7] {
  position: relative;
  left: 68px;
  top: -357px;
}
#btn_worried[data-v-8490feb7] {
  position: relative;
  top: -397px;
  left: -2px;
}
#btn_scared[data-v-8490feb7] {
  position: relative;
  top: -397px;
  right: -2px;
}
#face_neutral[data-v-8490feb7] {
  position: relative;
  top: -628px;
}
#welcome[data-v-8490feb7] {
  position: absolute;
}
#page_holder[data-v-8490feb7] {
  height: 100%;
  width: 100%;
  z-index: 100;
}
footer[data-v-8490feb7] {
  text-align: center;
  background-color: #cc3366;
}
footer a[data-v-8490feb7] {
  text-decoration: none;
}
footer p[data-v-8490feb7] {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 18px;
}
footer .smallfooter[data-v-8490feb7] {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
}
.icons img[data-v-8490feb7] {
  margin-bottom: 0px;
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 8px;
  text-decoration: none;
  width: 60px;
}

/* FLIP */
.flip-container[data-v-8490feb7] {
  -webkit-perspective: 1000px;
          perspective: 1000px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  display: inline-block;
  vertical-align: middle;
  padding: 30px 0px;
}
.flip-container:hover .back[data-v-8490feb7] {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}
.flip-container:hover .front[data-v-8490feb7] {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.flip-container[data-v-8490feb7],
.front[data-v-8490feb7],
.back[data-v-8490feb7] {
  width: 260px;
  height: 260px;
}
.flipper[data-v-8490feb7] {
  transition: 1s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: relative;
}
.front[data-v-8490feb7],
.back[data-v-8490feb7] {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: 1s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 20px;
}
.front[data-v-8490feb7] {
  z-index: 0;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  position: relative;
  margin-top: -30px;
}
.front h2[data-v-8490feb7] {
  text-align: center;
  height: -20px;
  line-height: 20px;
  padding-top: 20px;
}
.front img[data-v-8490feb7] {
  padding-bottom: 20px;
}
.back[data-v-8490feb7] {
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}
.back p[data-v-8490feb7] {
  font-size: 16px;
  margin-top: -25px;
  margin-right: 44px;
  margin-left: 44px;
  color: #000;
  text-align: center;
}
img.map[data-v-8490feb7],
map area[data-v-8490feb7] {
  outline: none;
}
.container[data-v-8490feb7] {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.flipcontainer[data-v-8490feb7] {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 180px;
  margin: 10px !important;
}
.card[data-v-8490feb7] {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
}
.card div[data-v-8490feb7] {
  display: block;
  height: 100%;
  width: 100%;
  line-height: 260px;
  text-align: center;
  font-weight: bold;
  font-size: 140px;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.card .frontCard[data-v-8490feb7] {
}
.card .backCard[data-v-8490feb7] {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.card.flipped[data-v-8490feb7] {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
#how1[data-v-8490feb7],
#how2[data-v-8490feb7],
#how3[data-v-8490feb7] {
  opacity: 0;
  transition: opacity 1s ease-in;
}
