#emotion-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-image: url("../assets/img//Bg_finish_dance.png");
  background-size: contain;
  text-align: center;
  width: 50vw;
  height: 65vh;
  z-index: 9999;
}

#emotion-modal-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* grid-template-columns: 12% 76% 12%;
  grid-template-rows: 19% 70% 11%; */
  justify-content: center;
  align-items: center;
  background-color: #fa2d73;
  color: #ffffff;
  /* -webkit-border-radius: 10px;
  -moz-border-radius: 10px; */
  border-radius: 10px;
  border: solid 8px #bcbcbc;
  /* box-shadow: 0 0 0 4px #6e6e6e; */
  z-index: 9999;
}

#emotion-modal-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  height: auto;
  width: auto;
}

#question-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
  height: 65vh;
  background-color: #fa2d73;
  color: #ffffff;
  border-radius: 10px;
  border: solid 8px #dddbdb;
  box-shadow:
          /*0 0 0 10px #6e6e6e,*/ 0 0 0 4px #6e6e6e;
}

#emoji-modal {
/* width: 636px; */
  /* background-image: url("../assets/img//Bg_finish_dance.png"); */
  /* background-color: #fa2d72; */
  /* background-size: cover;
  background-repeat: no-repeat; */
  grid-row: 2;
  text-align: center;
  z-index: 9999;
  align-items: center;
  max-height: 100%;
  max-width: 100%;

  height: 90%;
  width: auto;
  
  display: grid;
  grid-template-rows: 15% 70% 15%;
    justify-content: center;
}

.emoji-modal-message {
  font-size: 3em;
  color: white;
}

#emoji-modal-message-top {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column: auto / span 3;
  margin: auto;
  padding: 10px;
  font-size: 32px;
  text-align: center;
}

#emoji-modal-message-bottom {
  grid-row-start: 3;
  grid-column: auto / span 3;
  margin: auto;
  color: #bcbcbc;
  font-size: 1.5em;
}

#emoji {
  height: 75%;
  width: auto;
  margin: 10px 10px;

}

#buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
#buttons * {
  margin: 25px;
}
#buttons .modal-btn {
  scale: 1.2;
}
#buttons p {
  font-size: 18px;
}

.modal-btn {
  margin: 15px;
  width: 160px;
}
/* #homeLogin {
  position: fixed;
  top: 0px;
  right: 20px;
  display: flex;
  flex-direction: row;
}

#homeBtn {
  height: 28px;
}

#profileBtn {
  height: 28px;
}

#homeLogin #submit {
  padding: 0;
  border: none;
  background: none;
}

#LogoutBtn {
  height: 28px;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Nunito", sans-serif;
  background-color: #cc3366;
  line-height: normal;
  color: #f5f5f5;
} */

a {
  color: #fff;
}

a:hover {
  color: #f5f5f5;
}

img {
  vertical-align: baseline;
}

/* .logout {
  position: absolute;
  top: 80px;
  right: 10px;
} */

/* .textLabel {
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
  padding-top: 10px;
  margin-top: 10px;
  font-weight: 300px;
} */

#emot_overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 15%;
  width: 600px;
  height: 55%;
  text-align: center;
  padding: 30px;
  border-radius: 10px;
  border: solid 8px #dddbdb;
  box-shadow: 0px 0px 30px rgb(49, 12, 24);
  z-index: 9999;
}
#emot_overlay img {
}

.emotion-wheel-holder {
  height: 300px;
  scale: 0.9;
  padding: 0;
  margin-bottom: 20px;
}

#emot_overlay2 {
  display: flex;
  background-color: #fa2d73;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 50px 20px;
  border-radius: 10px;
  border: solid 8px #dddbdb;
  box-shadow: 0 0 0 4px #6e6e6e;
  z-index: 19999;
  text-align: center;
  box-shadow: 0px 0px 30px rgb(49, 12, 24);
}

.overlay {
  position: absolute;
  top: 15%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin: auto;
  padding-top: 50px;
  height: 35%;
  width: 600px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  border: solid 8px #dddbdb;
  box-shadow:
          /*0 0 0 10px #6e6e6e,*/ 0 0 0 4px #6e6e6e;
  z-index: 9990;
  background-color: #fa2d73;
  box-shadow: 0px 0px 30px rgb(49, 12, 24);
}
.overlay * {
  margin: 0px;
}

#message {
  color: #ffffff;
  font-family: "Nunito", sans-serif;
  font-size: 24px;
  text-align: center;
  vertical-align: middle;
}

.hideScreen {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  color: white;
  width: 100%;
  height: 100%;
  border: 0px;
  margin: 0px;
  padding: 0px;
  display: inline;
}

#emot_image {
  height: 318px;
  max-height: 318px;
  width: 318px;
  max-width: 318px;
  text-align: center;
  margin: 50px 141px;
  padding-top: 20px;
}

#closeLink {
  margin-top: 20px;
  color: white;
  opacity: .6;
  font-family: "Roboto", sans-serif;
  font-size: 1.5em;
  cursor: pointer;
}
#closeLink:hover {
  color: #ffa2c1;
}

#emot_text_small {
  font-family: "Nunito", sans-serif;
  color: white;
  opacity: .6;
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 100;
  padding: 0px;
  margin-top: 0px;
  position: relative;
}

#emot_text {
  text-align: center;
  font-size: 36px;
  font-weight: 400;
  padding: 0px !important;
  margin: 0px !important;
}
.emot_text_below {
  margin: 0px;
  font-size: 24px;
}

.gap {
  display: inline-block;
  width: 10px;
}

/* OVERLAY */

#btn_happy:hover,
#btn_sad:hover,
#btn_worried:hover {
  opacity: 1;
}

#btn_happy {
  position: relative;
  top: -318px;
  left: -1px;
}

#btn_sad {
  position: relative;
  top: -318px;
  right: -3px;
}

#btn_tired {
  position: relative;
  left: -69px;
  top: -357px;
}

#btn_angry {
  position: relative;
  left: 68px;
  top: -357px;
}

#btn_worried {
  position: relative;
  top: -397px;
  left: -2px;
}

#btn_scared {
  position: relative;
  top: -397px;
  right: -2px;
}

#face_neutral {
  position: relative;
  top: -628px;
}

#welcome {
  position: absolute;
}

#page_holder {
  height: 100%;
  width: 100%;
  z-index: 100;
}

.horizon {
  color: white;
  text-align: center;
  position: relative;
  top: 0%;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: visible;
  visibility: visible;
  display: block;
}

.horizon2 {
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  height: 1px;
  overflow: visible;
  visibility: visible;
  display: block;
}

.center {
  position: absolute;
  left: 50%;
  width: 90%;
  /* Overall width of div */
  height: 600px;
  /* Overall height of div */
  top: 30%;
  /* half of the overall height */
  margin-left: -450px;
  /* half of the overall width */
  visibility: visible;
}

.center2 {
  position: absolute;
  left: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 90%;
  /* Overall width of div */
  /* Overall height of div */
  /* half of the overall height */
  /* half of the overall width */
  -webkit-transform: translate3d(-5%, 60%, 0);
          transform: translate3d(-5%, 60%, 0);
  visibility: visible;
  z-index: 454554;
}

.center3 {
  position: absolute;
  left: 50%;
  width: 600px;
  /* Overall width of div */
  height: 250px;
  /* Overall height of div */
  top: -125px;
  /* half of the overall height */
  margin-left: -300px;
  /* half of the overall width */
  visibility: visible;
  z-index: 454553;
}


#how1,
#how2,
#how3 {
  opacity: 0;
  font-size: 20px;
  transition: opacity 1s ease-in;
}

#emotion-message-h2 {
  text-decoration: none;
  font-weight: 600;
  font-size: 52px;
  margin-top: 0 !important;
  margin-bottom: 20px;
}

.modal-bkg-overlay {
  position: absolute; /* We're using absolute positioning within the grid container */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #171616;  /*semi-transparent black background */
  z-index: 200;
  opacity: 90%;

}

@media screen and (max-width: 600px) {
  #emot_overlay {
    height: 70%;
    width: 75%;
    top: 12%;
  }
  .overlay {
    width: 72%;
  }
  #message {
    font-size: 18px;
  }
  #emot_text {
    font-size: 27px;
  }
  #emot_text_small {
    font-size: 20px;
  }
}

@media screen and (min-height: 600px) and (max-height: 800px) {
  #emot_overlay {
    flex-direction: column;
    height: 70%;
    width: 75%;
    top: 13%;
  }
}

@media (max-height: 600px) {
  #emot_overlay {
    flex-direction: row;
    width: 75%;
    height: 67%;
    top: 6%;
  }
  #emot_text {
    font-size: 26px;
  }
  #emot_text_small {
    font-size: 20px;
    order: 3;
  }
  .emotion-wheel-holder {
    height: 300px;
    scale: 0.75;
  }
  .overlay {
    width: 72%;
    height: 65%;
    top: 5%;
  }
}

@media (max-width: 900px) {
  #emotion-modal {
    width: 85vw;
    height: 90vh;
  }
  #question-modal {
    width: 85vw;
    height: 90vh;
  }
  #buttons {
    flex-direction: column;
  }
  #buttons * {
    margin: 15px;
  }
}
@media screen and (min-width: 901px) and (max-width: 1200px) {
  #buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #buttons .modal-btn {
    scale: 1;
  }
  #emotion-modal {
    width: 60vw;
    height: 80vh;
  }
  #question-modal {
    width: 60vw;
    height: 80vh;
  }
  #buttons * {
    margin: 8px;
  }
}
@media screen and (max-width: 710px) {
  #emot_overlay2 {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 85vw;
  }
}
@media (max-height: 600px) {
  #emotion-modal {
    width: 50%;
    height: 90%;
  }
  #buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  /* #emot_overlay2 {
    height: 100vh;
    width: 100vw;
  } */
  #emotion-modal {
    width: 60vw;
    height: 80vh;
  }
  #question-modal {
    width: 90vw;
    height: 100vh;
  }
}