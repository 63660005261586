.horizon3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  top: 10%;
  left: 0px;
  width: 100vw;
  height: 100vh;
  color: white;
  text-align: center;
  position: absolute;
  overflow: visible;
  visibility: visible;
  z-index: 9999;
}

.energy-level-container {
  background-color: #fa2d73;
  display: flex;
  flex-direction: column;
  flex-direction: row;
  padding-bottom: 20px;
}

.center {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top: 15%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  visibility: visible;
}

.center2 {
  position: absolute;
  left: 50%;
  width: 600px;
  /* Overall width of div */
  height: 600px;
  /* Overall height of div */
  top: -300px;
  /* half of the overall height */
  margin-left: -300px;
  /* half of the overall width */
  visibility: visible;
  z-index: 454554;
}

.center3 {
  position: absolute;
  left: 50%;
  width: 600px;
  /* Overall width of div */
  height: 250px;
  /* Overall height of div */
  top: -125px;
  /* half of the overall height */
  margin-left: -300px;
  /* half of the overall width */
  visibility: visible;
  z-index: 454553;
}

#how1,
#how2,
#how3 {
  opacity: 0;
  transition: opacity 1s ease-in;
  /* margin-bottom: 10px; */
}

.flip-table-top {
  /* margin-bottom: 5px; */
}

.flip-table-bottom {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

@media screen and (max-width: 710px) {
  .horizon3 {
    margin: 0px;
  }
  .center {
    top: 3%;
  }
  .energy-level-container {
    flex-direction: column;
  }
}

@media screen and (max-height: 600px) {
  .horizon3 {
    margin: 0px;
  }
  .center {
    top: 5%;
  }
  .energy-level-container {
    flex-direction: row;
  }
}